<template>
  <div id="app" class="container">
    <div class="row center">
      <Quiz></Quiz>
    </div>
    <Comparator :recoMotors="reco"></Comparator>
  </div>
</template>

<script>
import Comparator from "./components/Comparator.vue";
import Quiz from "./components/Quiz.vue";
export default {
  name: "App",
  components: {
    Quiz,
    Comparator,
  },
  data() {
    return {
      reco: [0, 1, 2],
    };
  },
};
</script>
<style>
html{
  font-size: 10px;
}
@font-face {
  font-family: "lr_web-bold_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/LandRoverWeb-Bold.woff");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "lr_web-medium_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/LandRoverWeb-Medium.woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "lr_ot4-bold_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Bold.eot");
  src: url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Bold.woff")
      format("woff"),
    url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Bold.ttf")
      format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "lr_ot4-medium_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Medium.eot");
  src: url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Medium.woff")
      format("woff"),
    url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Medium.ttf")
      format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "lr_ot4-light_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Ligth.eot");
  src: url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Ligth.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Ligth.woff")
      format("woff"),
    url("https://cdn2.jlrfrance.fr/webfonts/LandRoverOT4-Ligth.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Avenir-medium_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Medium.eot"); /* IE9 Compat Modes */
  src: url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Medium.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Medium.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Avenir-roman_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Roman.eot"); /* IE9 Compat Modes */
  src: url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Roman.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Roman.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Avenir-roman-oblique_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Roman-Oblique.eot"); /* IE9 Compat Modes */
  src: url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Roman-Oblique.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Roman-Oblique.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Avenir-heavy_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Heavy.eot"); /* IE9 Compat Modes */
  src: url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Heavy.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn2.jlrfrance.fr/webfonts/Avenir_Heavy.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Ascender_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/AscenderSerifW05-Regular.woff2");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "icons-landrover";
  src: url("https://cdn2.jlrfrance.fr/webfonts/pic-landrover.eot?8tynwf");
  src: url("https://cdn2.jlrfrance.fr/webfonts/pic-landrover.eot?8tynwf#iefix")
      format("embedded-opentype"),
    url("https://cdn2.jlrfrance.fr/webfonts/pic-landrover.ttf?8tynwf")
      format("truetype"),
    url("https://cdn2.jlrfrance.fr/webfonts/pic-landrover.woff?8tynwf")
      format("woff"),
    url("https://cdn2.jlrfrance.fr/webfonts/pic-landrover.svg?8tynwf#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@import url("https://cdn2.jlrfrance.fr/spe/10098/landrover2.css");
</style>