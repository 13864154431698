<template>
  <div class="comparatorCard center">
    <div class="background-1 padding heading">
      <picture>
        <img
          :src="
            'https://cdn2.jlrfrance.fr/uploads/10098/landrover/' +
            content.infos.header.picto
          "
          :alt="content.name"
        />
      </picture>
      <h3 class="heading_3 strong">{{ content.infos.header.titre }}</h3>
      <p>{{ content.name }}</p>
    </div>
    <div class="crit0">
      <img
        src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/LRDX-car.svg"
        alt=""
      />
      <p v-html="content.infos.criteres[0]"></p>
    </div>
    <div class="crit1">
      <img
        src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/LRDX-electric.svg"
        alt=""
      />
      <p v-html="content.infos.criteres[1]"></p>
    </div>
    <div class="crit2">
      <img
        src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/LRDX-co2.svg"
        alt=""
      />
      <p v-html="content.infos.criteres[2]"></p>
    </div>
    <div class="crit3">
      <img
        src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/LRDX-invoice.svg"
        alt=""
      />
      <p v-html="content.infos.criteres[3]"></p>
    </div>
    <div class="crit4">
      <img
        src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/LRDX-vignette.svg"
        alt=""
      />
      <p v-html="content.infos.criteres[4]"></p>
    </div>
    <div class="crit5">
      <img
        src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/LRDX-weight.svg"
        alt=""
      />
      <p v-html="content.infos.criteres[5]"></p>
    </div>
    <div class="crit6">
      <img
        src="https://cdn2.jlrfrance.fr/uploads/10098/landrover/LRDX-places.svg"
        alt=""
      />
      <p v-html="content.infos.criteres[6]"></p>
    </div>
    <a :href="content.infos.links[0]" class="btn_1" target="_blank"
      >En savoir plus</a
    >
    <br />
    <!--<a :href="content.infos.links[1]" class="btn_4">Découvrez la gamme</a>-->
  </div>
</template>
<script>
export default {
  name: "Card",
  props: ["content"],
};
</script>